// import "intersection-observer";
import * as $ from 'jquery';
import "bootstrap/js/src/util";
import "bootstrap/js/src/tab";
// import "bootstrap/js/src/modal";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/collapse";
import "bootstrap/js/src/scrollspy";
import "bootstrap/js/src/offcanvas";

// import "./site/owl";

// window.addEventListener("load", (event) => {
//     let boxElement = document.querySelector("#header");
//     function createObserver() {
//         let observer;
//         let options = {
//             root: null,
//             rootMargin: "0px",
//             threshold: 0
//         };
//
//         observer = new IntersectionObserver(handleIntersect2, options);
//         observer.observe(boxElement);
//     }
//     createObserver();
// }, false);
//
//
//
// function handleIntersect2(entries, observer) {
//     entries.forEach((entry) => {
//         if (entry.intersectionRatio <= 0) return $(document.querySelector("#box")).addClass("fixed-top");
//         else return $(document.querySelector("#box")).removeClass("fixed-top");
//     });
// }

$(document).ready(() => {
    window.onscroll = function (e) {
        let tt = document.querySelector("#toTop");
        if(isTall()){
            $(tt).addClass('show');
        } else {
            $(tt).removeClass('show');
        }
        function isTall() {
            let scrollDistance = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
            return scrollDistance > document.documentElement.clientHeight;
        }
    };
});

